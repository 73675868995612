<template>
  <div class="reset-step1-container">
    <div class="input-item">
      <custom-input
        type="text"
        inputTitle="手机号"
        :error-tip="errorTip.phoneNo"
        v-model="verifyParams.phoneNo"
        placeholder="请输入注册手机号"
        @blur="checkPhoneStatus"
      />
    </div>
    <div class="input-item">
      <div class="verifycode-wrap">
        <custom-input
          type="text"
          inputTitle="验证码"
          :error-tip="errorTip.verifyCode"
          v-model="verifyParams.verifyCode"
          placeholder="请输入短信验证码"
        />
      </div>
      <span class="verifycode-btn" @click="sendMsg" :class="{disabled:count<60}">{{btnText}}</span>
    </div>
    <el-button type="primary" class="next-btn" @click="handleNext"
      >下一步</el-button
    >
  </div>
</template>
<script>
import CustomInput from '@/components/CustomInput/CustomInput'
// import {
//   pwdHashEncryptSave,
//   pwdAesEncrypt
// } from '@/utils/encryptPwd'
import { getRegisterStatus, checkVerifyCodeForForget, getVerifyCodeForForget, challengeCode } from '@/api/eits'

export default {
  name: 'ResetStep1',
  components: { CustomInput },
  data () {
    return {
      errorTip: {
        phoneNo: '',
        verifyCode: ''
      },
      verifyParams: {
        phoneNo: '',
        type: 1,
        verifyCode: ''
      },
      params: {
        codeId: '',
        password: '',
        type: 1,
        salt: '',
        verifyCode: ''
      },
      count: 60,
      timer: null,
      btnText: '获取验证码'
    }
  },
  methods: {
    // 校验手机号是否注册/绑定过
    async checkPhoneStatus () {
      const { phoneNo } = this.verifyParams
      if (!this.checkPhone()) {
        setTimeout(() => {
          this.sliderStatus = 0
        }, 0)
        return
      }
      const { code, msg } = await getRegisterStatus({
        phoneNo,
        type: 1
      })
      if (code !== '200') {
        this.errorTip.phoneNo = msg
      }
    },
    handleNext () {
      if (!this.checkPhone() || !this.checkVerifyCode()) {
        return
      }
      this.checkCode()
    },
    /**
    * @Author: wangqiangrong
    * @Date: 2021-03-31 19:27:51
    * @Desc: 处理验证码组件触发的“发送验证码”事件
    */
    async sendMsg () {
      if (!this.checkPhone() || this.count < 60) {
        return
      }
      const { phoneNo, type, productCode } = this.verifyParams
      const { code, msg } = await getVerifyCodeForForget({
        phone: phoneNo,
        type,
        productCode
      })
      if (code === '200') {
        this.countDown()
      } else {
        this.errorTip.phoneNo = msg
      }
    },
    // 校验验证码
    async checkCode () {
      const { phoneNo, type, productCode, verifyCode } = this.verifyParams
      const { code, data } = await checkVerifyCodeForForget({
        phone: phoneNo,
        code: verifyCode,
        productCode,
        type
      })
      if (code === '200') {
        this.params.verifyCode = data.verifyCode
        this.getChallengeCode()
      }
    },
    /**
    * @Author: wangqiangrong
    * @Date: 2021-03-31 19:27:59
    * @Desc: 获取challengeCode
    */
    async getChallengeCode () {
      const { phoneNo, type } = this.verifyParams
      const { data, code } = await challengeCode({
        name: phoneNo,
        type
      })
      if (code === '200') {
        this.params.codeId = data.codeId
        this.params.salt = data.salt
        this.params.vCode = data.vcode || data.vCode
        sessionStorage.setItem('resetPwdParams', JSON.stringify(this.params))
        this.$emit('stepchange', 1)
      }
    },
    /**
    * @Author: wangqiangrong
    * @Date: 2021-03-31 19:13:03
    * @Desc: 倒计时
    */
    countDown () {
      this.timer = setInterval(() => {
        if (this.count > 0) {
          this.count--
          this.btnText = `${this.count}s后重新获取`
        } else {
          this.count = 60
          clearInterval(this.timer)
          this.btnText = '获取验证码'
        }
      }, 1000)
    },
    /**
     * @Author: wangqiangrong
     * @Date: 2021-03-31 19:05:24
     * @Desc: 加验手机号
     */
    checkPhone () {
      const { phoneNo } = this.verifyParams
      const reg = /^1\d{10}$/
      let str = ''
      if (!phoneNo) {
        str = '请输入手机号'
      } else if (!reg.test(phoneNo)) {
        str = '请输入正确的手机号'
      }
      this.errorTip.phoneNo = str
      return !str
    },
    /**
     * @Author: wangqiangrong
     * @Date: 2021-03-31 19:07:24
     * @Desc: 校验验证码
     */
    checkVerifyCode () {
      const { verifyCode } = this.verifyParams
      if (!verifyCode) {
        this.errorTip.verifyCode = '请输入验证码'
        return false
      }
      return true
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>
<style lang="scss" scoped>
.input-item {
  width: 504px;
  margin-bottom: 40px;
  .verifycode-wrap {
    width: 376px;
    display: inline-block;
  }
  .verifycode-btn {
    width: 120px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    text-align: center;
    line-height: 44px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.3);
    float: right;
    cursor: pointer;
    &.active {
      border-color: #0059ff;
    }
    &:hover {
      border-color: #0059ff;
      color: #0059ff;
    }
  }
  .disabled{
    cursor: not-allowed;
    &:hover {
      border-color: rgba(0, 0, 0, 0.3);
      color: rgba(0, 0, 0, 0.3);
    }

  }
}
.next-btn {
  width: 400px;
  height: 48px;
  font-size: 18px;
  margin: 22px 0 24px;
  max-width: initial;
  border-radius: 4px;
  float: right;
}
</style>
