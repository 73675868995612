<template>
  <div class="reset-step1-container">
    <div class="input-item">
      <custom-input type="password" inputTitle="新密码" tips="8~16位，字母、数字、字符两种以上的组合。" :error-tip="errorTip.password" v-model="params.password" placeholder="请输入新密码" @blur="checkNewPwd"/>
    </div>
    <div class="input-item">
      <custom-input type="password" inputTitle="确认密码" :error-tip="errorTip.confirmPwd" v-model="confirmPwd" placeholder="请再次输入确认密码" @blur="checkConfirmPwd"/>
    </div>
    <el-button
      type="primary"
      class="next-btn"
      @click="handleNext"
    >下一步</el-button>
  </div>
</template>
<script>
import CustomInput from '@/components/CustomInput/CustomInput'
import { changePwd } from '@/api/eits'
import { pwdHashEncryptSave, pwdAesEncrypt } from '@/utils/util'

export default {
  name: 'ResetStep1',
  components: { CustomInput },
  data () {
    return {
      errorTip: {
        password: '',
        confirmPwd: ''
      },
      params: {},
      confirmPwd: ''
    }
  },
  mounted () {
    const params = sessionStorage.getItem('resetPwdParams')
    // 如果有从第一步带参数过来，那么直接使用，否则给默认值
    if (params) {
      this.params = JSON.parse(params)
    } else {
      this.params = {
        codeId: '',
        password: '',
        type: 1,
        salt: '',
        verifyCode: ''
      }
    }
  },
  methods: {
    async handleNext () {
      if (!this.checkNewPwd() || !this.checkConfirmPwd()) {
        return
      }
      this.updatePwd()
    },
    /**
    * @Author: wangqiangrong
    * @Date: 2021-03-31 19:58:15
    * @Desc: 修改密码
    */
    async updatePwd () {
      const { salt, password, vCode } = this.params
      const { code } = await changePwd({
        ...this.params,
        password: pwdAesEncrypt(pwdHashEncryptSave(password, salt), vCode, salt)
      })
      if (code === '200') {
        this.$emit('stepchange', 2)
      }
    },
    /**
    * @Author: wangqiangrong
    * @Date: 2021-03-31 20:05:09
    * @Desc: 校验新密码
    */
    checkNewPwd () {
      const { password } = this.params
      return this.checkPwd(password, 'password')
    },
    /**
    * @Author: wangqiangrong
    * @Date: 2021-03-31 20:05:53
    * @Desc: 校验确认密码
    */
    checkConfirmPwd () {
      const { password } = this.params
      const { confirmPwd } = this
      if (!this.checkPwd(confirmPwd, 'confirmPwd', '请输入确认密码', '请输入正确的确认密码')) {
        return false
      }
      if (password !== confirmPwd) {
        this.errorTip.confirmPwd = '两次输入的新密码不一致'
        return false
      }
      this.errorTip.confirmPwd = ''
      return true
    },
    checkPwd (pwd, type, emptyMsg, errorMsg) {
      const reg = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{8,16}$/
      if (!pwd) {
        this.errorTip[type] = emptyMsg || '请输入新密码'
        return false
      }
      if (!reg.test(pwd)) {
        this.errorTip[type] = errorMsg || '请输入正确的新密码'
        return false
      }
      this.errorTip[type] = ''
      return true
    }
  }
}
</script>
<style lang="scss" scoped>
.input-item{
  width: 504px;
  margin-bottom: 40px;
}
.next-btn{
  width: 400px;
  height: 48px;
  font-size: 18px;
  margin: 22px 0 24px;
  max-width: initial;
  border-radius: 4px;
  float: right;
}
</style>
