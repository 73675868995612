<template>
    <div class="reset-pwd-container">
      <div class="reset-pwd-content">
        <div class="reset-top">
          <div class="breadcrumb">
            <i class="h-icon-angle_left" @click="backToIndex"></i>
            登录
            <i class="h-icon-angle_right"></i>
            <span>重置密码</span>
          </div>
          <div class="flex">
            <el-steps :space="322" :active="active" finish-status="success">
              <el-step :title="list" v-for="list in steps" :key="list"/>
            </el-steps>
          </div>
        </div>
        <div class="reset-main">
          <step1 v-if="active===0" @stepchange="(val) => active = val"/>
          <step2 v-if="active===1" @stepchange="(val) => active = val"/>
          <operate-success v-if="active===2" @stepchange="(val) => active = val"/>
        </div>
      </div>
    </div>
</template>
<script>
import Step1 from './Step1'
import Step2 from './Step2'
import OperateSuccess from './OperateSuccess'
const steps = ['确认账号', '重置密码', '完成']
export default {
  components: { Step1, Step2, OperateSuccess },
  data () {
    return {
      steps,
      active: 0
    }
  },
  methods: {
    backToIndex () {
      window.history.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.reset-pwd-container{
  background-color: rgb(245,247,249);
  // height: 600px;
  flex: 1;
}
.reset-pwd-content{
  width: 1280px;
  margin: 0 auto;
  height: 100%;
  background-color: #fff;
  .reset-top {
    background-color: #fff;
    height: 216px;
    display: flex;
    flex-direction: column;
    margin-bottom: 108px;
    .flex{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 100px;
      border-bottom: 1px solid rgba(0,0,0,0.12);;
    }
    .breadcrumb{
      height: 56px;
      display: flex;
      align-items: center;
      padding-left: 12px;
      border-bottom: 1px solid #eee;
      color: #4d4d4d;
      span{
        color: #1a1a1a;
        font-weight: 600;
      }
      i{
        display: inline-block;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 24px;
      }
      .h-icon-angle_left{
        width: 22px;
        height: 22px;
        line-height: 22px;
        border: 1px solid #4d4d4d;
        border-radius: 50%;
        margin-right: 12px;
        cursor: pointer;
      }
    }
    &::v-deep .el-step__head{
      width: 20px;
      height: 20px;
      font-size: 12px;
    }
    &::v-deep .el-step__main .el-step__title{
      font-size: 12px;
      color: #4C4C4C;
    }
    &::v-deep .el-step__line.is-horizontal{
      top:10px;
    }
    &::v-deep .el-step.is-horizontal{
      width: 322px;
      &:last-of-type{
        width: 0!important;
        flex-basis:34px!important;
      }
    }
  }
  .reset-main{
    display: flex;
    justify-content: center;
  }
}
</style>
